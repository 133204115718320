body {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  margin: 0;
  /* font-family: 'Noto Sans JP', serif; */
  line-height: 1.5;
}

header {
  background: white;
  padding: 1rem 5vw;
  width: 100%;
  position: fixed;
  z-index: 100;
  top: 0;
  border-bottom: 2px solid whitesmoke;
  -moz-box-shadow:    1px 1px 10px 3px rgba(0,0,0,0.1);
  -webkit-box-shadow: 1px 1px 10px 3px rgba(0,0,0,0.1);
  box-shadow:         1px 1px 10px 3px rgba(0,0,0,0.1);
}

div {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
}

p {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
}

h1 {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
}

h2 {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
}

h3 {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
}

h4 {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
}

.concept {
  text-align: -webkit-center;
  font-style: italic;
}

.languages {
  padding: 1rem 5vw;
  top: 0;
  right:0;
  position: fixed;
  display: inline-block;
  z-index: 100;
}

.tickets {
  padding: 1rem 5vw;
  top: 0;
  left:20%;
  position: fixed;
  display: inline-block;
  z-index: 100;
}

.languagesbutton {
  display: inline-block;
  padding-left: 10px;
  cursor: pointer;
}

.infotext {
  text-align: -webkit-center;
  font-weight: bold;
}

html {
  scroll-behavior: smooth;
}

header a {
  color: black;
  font-weight: 800;
  text-decoration: none;
}

main {
  margin: 4rem auto 4rem;
  max-width: 650px;
  width: 90vw;
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  z-index: 1;
}

.success {
text-align: center;
}

.cartwrapper {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
}

.cartsummary {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(3, 1fr);
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
}

.cartsummaryempty {
  display: grid;
  gap: 2rem;
  font-style: italic;
  grid-template-columns: repeat(3, 1fr);
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
}

.cartsummarybuttons {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(3, 1fr);
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
}

.cartsummarytotal{
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(3, 1fr);
  font-weight: bold;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }

.buttonwrapper {
  text-align: center;
}

.buttonwrapperpurchase {
  text-align: center;
  background-color: rgb(255,240,200);
}

footer {
  color: #444;
  font-size: 0.875rem;
  text-align: center;
}

footer a {
  color: inherit;
}

.price {
  font-style: italic;
}

.products {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 3rem;
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
}

.product img {
  max-width: 100%;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.product form {
  align-items: baseline;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 70px 50px 1fr;
}

.product input {
  border: 1px solid navy;
  border-radius: 0.25rem;
  font-size: 1.125rem;
  line-height: 1.25rem;
  padding: 0.25rem;
}

button {
  background: white;
  border-radius: 0.5rem;
  color: black;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  padding: 0.25rem;
  margin: 0.5rem;
  display: inline;
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  font-weight: 600;
}

.productbutton {
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 0rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background: whitesmoke;
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
}

button:disabled {
  opacity: 0.5;
}
